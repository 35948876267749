"use client";
import { SiteContext } from "@/context";
import { getDeeplinkUrlForSite } from "@/deeplinks";
import { useTracking } from "@/tracking";
import { CSSProperties, useContext } from "react";

const DownloadLink = ({
  className,
  style,
  children,
}: React.PropsWithChildren<{
  style?: CSSProperties;
  className?: string;
  href?: string;
}>) => {
  const { trackingId, trackConversion } = useTracking();
  const { site } = useContext(SiteContext);

  const href = getDeeplinkUrlForSite(site, { l: trackingId });

  return (
    <a
      href={href}
      onClick={() => {
        trackConversion();
      }}
      className={className}
      style={style}
    >
      {children}
    </a>
  );
};

export default DownloadLink;
