import { SITE } from "./types";

export function getDeeplinkUrlForSite(
  site: SITE,
  params: Record<string, string>
): string {
  const queryString = new URLSearchParams(params).toString();
  switch (site) {
    case "www.hyre.no":
      return `https://link.hyre.no/lastned/?${queryString}`;
    case "www.hyre.se":
      return `https://link.hyre.no/laddaned/?${queryString}`;
    case "www.hyre.dk":
      return `https://link.hyre.no/hent/?${queryString}`;
  }
}
