const gtagload = function (trackingId) {
  var new_script_section = document.createElement("script");
  new_script_section.async = !0;
  new_script_section.src = `https://www.googletagmanager.com/gtag/js?id=${trackingId}`;
  var insert_pos = document.getElementsByTagName("script")[0];
  insert_pos.parentNode.insertBefore(new_script_section, insert_pos);
};
export const gtaginit = function (trackingId) {
  gtagload(trackingId);
  window.dataLayer = window.dataLayer || [];
  window.gtag = function () {
    window.dataLayer.push(arguments);
  };
  window.gtag("js", new Date());
  /* This function is only called when we have consent from the user, see tracking/index.tsx.

     From March 2024, gtag requires that we tell them that the user has consented before we track conversions
     https://support.google.com/tagmanager/answer/13695607?hl=en&ref_topic=6003219

     Since we know that this code only runs when consent is granted, we can "default" to "granted", even though in
     practice our default is 'denied' – when the answer is denied, we don't even bother running google's code to tell
     them that consent is denied. */
  window.gtag("consent", "default", {
    ad_storage: "granted",
    ad_user_data: "granted",
    ad_personalization: "granted",
    analytics_storage: "granted",
  });
  window.gtag("config", trackingId);
};

export const gtagtrack = function (send_to) {
  /* This function is only called when we have consent from the user, see tracking/index.tsx.

     gtag doesn't store consent data and require us to update it on "every pageload before the user
     interacts with anything"
     https://developers.google.com/tag-platform/security/guides/consent#update_consent_state

     Since we know that this code only runs when consent is granted, we can "update" to "granted"
     This approach ensures it is at least called before we track a conversion. */
  window.gtag("consent", "update", {
    ad_storage: "granted",
    ad_user_data: "granted",
    ad_personalization: "granted",
    analytics_storage: "granted",
  });
  window.gtag("event", "conversion", {
    send_to: send_to,
    transaction_id: "",
  });
};
