const snapload = function (win, doc, sdk_url) {
  if (win.snaptr) return;
  var tr = (win.snaptr = function () {
    tr.handleRequest
      ? tr.handleRequest.apply(tr, arguments)
      : tr.queue.push(arguments);
  });
  tr.queue = [];
  var s = "script";
  var new_script_section = doc.createElement(s);
  new_script_section.async = !0;
  new_script_section.src = sdk_url;
  var insert_pos = doc.getElementsByTagName(s)[0];
  insert_pos.parentNode.insertBefore(new_script_section, insert_pos);
};
export const snapinit = function (trackingId) {
  snapload(window, document, "https://sc-static.net/scevent.min.js");
  window.snaptr("init", trackingId);
  snaptrack("PAGE_VIEW");
};

export const snaptrack = function (eventName) {
  window.snaptr("track", eventName);
};
