"use client";
import { useCallback, useContext, useEffect } from "react";
import { fbinit, fbtrack } from "./fb";
import { snapinit, snaptrack } from "./snap";
import { intercominit } from "./intercom";
import { gtaginit, gtagtrack } from "./gtag";
import { linkedininit } from "./linkedin";
import CookieConsent from "@/components/CookieConsent";
import { newTracker, trackPageView } from "@snowplow/browser-tracker";
import {
  LinkClickTrackingPlugin,
  enableLinkClickTracking,
  refreshLinkClickTracking,
} from "@snowplow/browser-plugin-link-click-tracking";
import Link from "next/link";
import { usePathname, useSearchParams } from "next/navigation";
import { SiteContext, TrackerContext } from "@/context";

let trackingInjected = false;
export const consentItemName = "consentGiven";

export const getConsent = function () {
  const storedValue = localStorage.getItem(consentItemName);
  if (storedValue === "true") {
    return true;
  }
  return false;
};

const trackingIdentifiers = {
  "www.hyre.no": {
    snowplowAppId:
      process.env.NODE_ENV === "production" ? "hyre.no" : "hyre.no-test",
    facebook: { init: "831047113743911", track: "PageView" },
    snap: {
      init: "df8654d2-4e8e-4381-8386-2b61d1bf1e07",
      track: "PAGE_VIEW",
    },
    gtag: {
      init: "AW-808316076",
      track: "AW-808316076/rxtuCNTLoMsBEKzZt4ED",
    },
    linkedin: { init: "1277076" },
  },
  "www.hyre.se": {
    snowplowAppId:
      process.env.NODE_ENV === "production" ? "hyre.se" : "hyre.se-test",
    facebook: { init: "3112350919094283", track: "PageView" },
    snap: {
      init: "d3bcd2f9-07e7-445a-82ff-83522e284c92",
      track: "PAGE_VIEW",
    },
    gtag: {
      init: "AW-10895134869",
      track: "AW-10895134869/XugWCM_ZwccDEJWhmsso",
    },
    linkedin: { init: "1277076" },
  },
  "www.hyre.dk": {
    snowplowAppId:
      process.env.NODE_ENV === "production" ? "hyre.dk" : "hyre.dk-test",
    facebook: { init: "511398801129685", track: "PageView" },
    snap: {
      init: "b226f231-e9bb-4fec-a50b-057599a451bb",
      track: "PAGE_VIEW",
    },
    gtag: {
      init: "AW-10896353324",
      track: "AW-10896353324/29lpCPT5y5EYEKzQ5Mso",
    },
    linkedin: { init: "1277076" },
  },
};

export function useInjectTracking() {
  const { site } = useContext(SiteContext);
  const { tracker, setTracker } = useContext(TrackerContext);

  const identifiers = trackingIdentifiers[site];

  useEffect(() => {
    if (!tracker) {
      const t = newTracker("sp1", "https://analytics.hyre.no", {
        appId: identifiers.snowplowAppId,
        plugins: [LinkClickTrackingPlugin()],
      });
      // newTracker returns null if it's already initialized from somewhere else.
      if (t) {
        setTracker(t);
        enableLinkClickTracking({ pseudoClicks: true });
      }
    }
  }, [identifiers.snowplowAppId, tracker, setTracker]);

  const pathname = usePathname();
  const searchParams = useSearchParams();
  useEffect(() => {
    trackPageView();
    refreshLinkClickTracking();
  }, [pathname, searchParams]);

  const injectTracking = useCallback(
    function () {
      // Do not initialize any 3rd party SDKs until user has consented
      if (!getConsent()) return;

      const intercomAppID =
        process.env.NODE_ENV === "production" ? "i7a63ss6" : "olx0nqoi";

      fbinit(identifiers.facebook.init);
      snapinit(identifiers.snap.init);
      intercominit(intercomAppID);
      gtaginit(identifiers.gtag.init);
      linkedininit(identifiers.linkedin.init);

      trackingInjected = true;
    },
    [identifiers]
  );

  useEffect(() => {
    if (trackingInjected) return;

    // Attempt to inject tracking (nothing happens if user has not accepted tracking)
    injectTracking();
  }, [injectTracking]);

  return injectTracking;
}

export function useTracking() {
  const { site } = useContext(SiteContext);
  const { tracker } = useContext(TrackerContext);

  const identifiers = trackingIdentifiers[site];

  const trackingId =
    (tracker?.getDomainUserId() as unknown as string | null) || "";

  const trackConversion = function () {
    if (!getConsent()) return;
    gtagtrack(identifiers.gtag.track);
    snaptrack(identifiers.snap.track);
    fbtrack(identifiers.facebook.track);
  };

  return { trackingId, trackConversion };
}

export const openConsentModal = () => {
  // @ts-ignore
  window.cookieConsentWindow?.openConsent();
};

// Component to inject tracking and modal
export const Tracking = () => {
  const { site } = useContext(SiteContext);
  const injectTracking = useInjectTracking();

  const t = {
    "www.hyre.no": {
      accept: "Godkjenn",
      decline: "Kun nødvendige",
      moreInfo: "Mer informasjon",
      lessInfo: "Mindre informasjon",
      cookieInfo: "Vi bruker følgende tredjeparts-cookies:",
      body: (
        <>
          Vi bruker cookies til første-parts analyse for å gjøre
          kundeopplevelsen din bedre. Utover dette kan du også godkjenne
          tredjeparts-sporing for mer relevant markedsføring. Les mer i{" "}
          <Link href="/vilk%C3%A5r/personvernerkl%C3%A6ring/">
            vår personvernerklæring
          </Link>
          .
        </>
      ),
      cookies: [
        {
          name: "Facebook (marketing)",
          description: "Brukes til å måle og forbedre effekten av annonsering.",
        },
        {
          name: "Snap (marketing)",
          description: "Brukes til å måle og forbedre effekten av annonsering.",
        },
        {
          name: "Google (marketing)",
          description: "Brukes til å måle og forbedre effekten av annonsering.",
        },
        {
          name: "Linkedin (marketing)",
          description: "Brukes til å måle og forbedre effekten av annonsering.",
        },
        {
          name: "Intercom (functional)",
          description: "Brukes til å gi mulighet til å kontakte kundeservice.",
        },
      ],
    },
    "www.hyre.se": {
      accept: "Godkänn",
      decline: "Endast nödvändiga",
      moreInfo: "Mer information",
      lessInfo: "Mindre information",
      cookieInfo: "Vi använder följande tredjeparts-cookies:",
      body: (
        <>
          Vi använder cookies till förstaparts-data för att förbättra din
          kundupplevelse. Utöver det kan du även godkänna tredjeparts-spårning
          för relevant marknadsföring. Läs mer under{" "}
          <Link href="/villkor/sekretesspolicy/">vår sekretesspolicy</Link>.
        </>
      ),
      cookies: [
        {
          name: "Facebook (marketing)",
          description:
            "Används för att mäta och förbättra effekten av marknadsföring.",
        },
        {
          name: "Snap (marketing)",
          description:
            "Används för att mäta och förbättra effekten av marknadsföring.",
        },
        {
          name: "Google (marketing)",
          description:
            "Används för att mäta och förbättra effekten av marknadsföring.",
        },
        {
          name: "Linkedin (marketing)",
          description:
            "Används för att mäta och förbättra effekten av marknadsföring.",
        },
        {
          name: "Intercom (functional)",
          description: "Används för att ge möjlighet att kontakta kundtjänst.",
        },
      ],
    },
    "www.hyre.dk": {
      accept: "Acceptér",
      decline: "Kun nødvendige",
      moreInfo: "Mere info",
      lessInfo: "Mindre info",
      cookieInfo: "Vi bruger følgende ikke-nødvendige cookies:",
      body: (
        <>
          Vi bruger cookies til førstepartsanalyse for at forbedre vores
          kundeoplevelse. Du kan også godkende tredjeparts-sporing for at få
          mere relevant markedsføring. Læs mere i{" "}
          <Link href="/vilk%C3%A5r/fortrolighedserkl%C3%A6ring/">
            vores Fortrolighedserklæring
          </Link>
          .
        </>
      ),
      cookies: [
        {
          name: "Facebook (marketing)",
          description:
            "Bruges til at måle og forbedre effekten af annonceringer.",
        },
        {
          name: "Snap (marketing)",
          description:
            "Bruges til at måle og forbedre effekten af annonceringer.",
        },
        {
          name: "Google (marketing)",
          description:
            "Bruges til at måle og forbedre effekten af annonceringer.",
        },
        {
          name: "Linkedin (marketing)",
          description:
            "Bruges til at måle og forbedre effekten af annonceringer.",
        },
        {
          name: "Intercom (functional)",
          description:
            "Bruges til at give mulighed for at kontakte kundesupport.",
        },
      ],
    },
  }[site];

  return (
    <CookieConsent
      ref={(ref) => {
        // @ts-ignore
        window.cookieConsentWindow = ref;
      }}
      onAccept={() => {
        injectTracking();
      }}
      cookies={t.cookies}
      acceptButtonText={t.accept}
      declineButtonText={t.decline}
      cookieMoreInfoButtonText={t.moreInfo}
      cookieLessInfoButtonText={t.lessInfo}
      cookieInfoText={t.cookieInfo}
    >
      {t.body}
    </CookieConsent>
  );
};
