"use client";
import { useTracking } from "@/tracking";
import { CSSProperties } from "react";

const BedriftLink = ({
  href,
  country,
  className,
  style,
  children,
}: React.PropsWithChildren<{
  style?: CSSProperties;
  country: "DNK" | "NOR" | "SWE";
  className?: string;
  href?: string;
}>) => {
  const { trackConversion, trackingId } = useTracking();

  const baseUrl =
    country == "SWE"
      ? "https://business.hyre.se"
      : country == "DNK"
      ? "https://business.hyre.dk"
      : "https://bedrift.hyre.no";

  return (
    <a
      href={`${baseUrl}/${href ?? "log-in"}?l=${trackingId}`}
      onClick={(e) => {
        trackConversion();
      }}
      className={className}
      style={style}
    >
      {children}
    </a>
  );
};

export default BedriftLink;
